import bolo from "../pages/bolo-milkmenk.webp"
import pao from "../pages/pao-de-queijo-com-creme-de-leite.webp"
import batata from "../pages/batata-gratinada-na-manteiga.webp"
import Torta from "../pages/Torta-cremosa-de-pacoca-768x536.png"
import Macarrao from "../pages/Macarrao-ao-queijo-e-tomates-assados-768x536.png"
import Fondue from "../pages/Fondue-de-Queijo.png"
import Coxinha from "../pages/coxinha.png"

import "./Receitas.css"

const Receitas = () => {
    return(
        <section id="Receita">
            <div className="receita-container">

            <div className="item">
                        <div className="item-img">
                            <div className="titulo-receita">
                                <h1>Torta Cremosa de Paçoca</h1>
                            </div>
                            <img src={Torta} alt="" />
                        </div>
                        <div className="ingredientes">
                            <h1>Ingredientes:</h1>
                            <ul>
                                <li>200 gramas de farinha de trigo ou 1 xícara de chá mais 1 colher de sopa;</li>
                                <li>Meia xícara de chá de manteiga Milk Menk gelada cortada em cubos;</li>
                                <li>Um quarto de xícara de chá de açúcar ou 45 gramas;</li>
                                <li>10 paçocas ou 160 gramas;</li>
                                <li>220 gramas de requeijão Milk Menk;</li>
                                <li>1 lata de leite condensado;</li>
                                <li>10 paçocas esfareladas ou 160 gramas;</li>
                                <li>2 ovos;</li>
                            </ul>
                        </div>
                        <div className="modo-preparo">
                            <h1>Mode de preparo:</h1>
                            <ul>
                                <li>Num processador, coloque 200 gramas de farinha de trigo, meia xícara de chá de manteiga gelada cortada em cubos, um quarto de xícara de chá de açúcar, 10 paçocas e bata até formar uma farofa.</li>
                                <li>Separe 1 xícara e meia de chá da farofa para a cobertura.</li>
                                <li>Numa forma de fundo falso, de 24 centímetros de diâmetro por 6 centímetros e meio de altura, forre o fundo e a lateral com a farofa de paçoca. Faça a lateral com 2 cm de altura.</li>
                                <li>Leve ao forno preaquecido a 180 graus Celsius por 15 minutos.</li>
                                <li>Retire do forno e reserve.</li>
                                <li>Coloque numa tigela 1 copo de requeijão, 1 lata de leite condensado, 10 paçocas esfareladas, 2 ovos e misture bem com um batedor de arame (fouet) até ficar um creme homogêneo.</li>
                                <li>Espalhe o creme sobre a massa pré-assada e, por cima do creme, espalhe 1 xícara e meia de chá da farofa reservada.</li>
                                <li>Leve ao forno preaquecido a 180 graus Celsius por 40 minutos.</li>
                                <li>Retire do forno, deixe esfriar, desenforme e sirva.</li>
                            </ul>
                        </div>
                    </div>

                    <div className="item">
                        <div className="item-img">
                            <div className="titulo-receita">
                                <h1>Macarrão ao Queijo e Tomates Assados</h1>
                            </div>
                            <img src={Macarrao} alt="" />
                        </div>
                        <div className="ingredientes">
                            <h1>Ingredientes:</h1>
                            <ul>
                                <li>500 g de macarrão curto (penne, fusilli, farfalle, etc.)</li>
                                <li>200 g de queijo ricota Milk Menk</li>
                                <li>1/2 xícara (chá) de azeite extra virgem</li>
                                <li>1/2 pimenta dedo-de-moça picada </li>
                                <li>500 g de tomates-cereja</li>
                                <li>4 dentes de alho picados </li>
                                <li>Sal e pimenta-do-reino a gosto</li>
                                <li>1 punhado de folhas frescas de manjericão</li>
                            </ul>
                        </div>
                        <div className="modo-preparo">
                            <h1>Mode de preparo:</h1>
                            <ul>
                                <li>Coloque um pouco de azeite no fundo de uma assadeira e coloque o queijo no centro. Cubra o queijo com a pimenta dedo-de-moça (caso use).</li>
                                <li>Coloque os tomates cereja em volto do queijo e regue com o azeite. Dê uma sacudida na assadeira para o queijo envolver bem os tomates.</li>
                                <li>Tempere com sal e pimenta-do-reino a gosto e leve para assar em forno preaquecido a 230ºC por 25 minutos, ou até o queijo derreter e os tomates ficarem bem macios.</li>
                                <li>Enquanto isso, cozinhe o macarrão de acordo com as instruções da embalagem, escorra e reserve.</li>
                                <li>Tire a assadeira do forno, e use uma colher ou espátula para quebrar os ingredientes e misturar tudo.</li>
                            </ul>
                        </div>
                    </div>

                    {/* <div className="item">
                        <div className="item-img">
                            <div className="titulo-receita">
                                <h1>Fondue de Queijo Milk Menk</h1>
                            </div>
                            <img src={Fondue} alt="" />
                        </div>
                        <div className="ingredientes">
                            <h1>Ingredientes:</h1>
                            <ul>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                            </ul>
                        </div>
                        <div className="modo-preparo">
                            <h1>Mode de preparo:</h1>
                            <ul>
                                <li>Faça o bolo como pede o rótulo da embalagem.</li>
                                <li>Coloque no forno para assar. Hidrate o coco ralado em 100 ml de água. É só misturá-los. (Faço isso para o coco não ficar tão seco)</li>
                                <li>Após retirar o bolo do forno, ainda morno, desenforme em uma bandeja. E Corte o bolo ao meio.</li>
                                <li>Em uma jarra, misture o leite de coco com o leite Milk Menk.</li>
                                <li>Coloque metade do bolo cortado de volta para o tabuleiro, e molhe o bolo com metade da mistura de leite de coco com o leite Milk Menk.Espalhe um pouco do coco ralado. (Guarde a maior parte para colocar por cima do bolo)</li>
                                <li>Passe o recheio de doce de leite no bolo. Se quiser misture um pouco de creme de leite Milk Menk ao doce, assim fica mais fácil na hora de espalhar.</li>
                                <li>Coloque a outra metade do bolo e molhe com o restante da mistura de leite de coco e leite Milk Menk.</li>
                                <li>Coloque o restante coco ralado hidratado por cima do bolo. Deixe na geladeira por 6 horas.</li>
                            </ul>
                        </div>
                    </div> */}

                    {/* <div className="item">
                        <div className="item-img">
                            <div className="titulo-receita">
                                <h1>Coxinha com Frango e Requeijão Milk Menk</h1>
                            </div>
                            <img src={Coxinha} alt="" />
                        </div>
                        <div className="ingredientes">
                            <h1>Ingredientes:</h1>
                            <ul>
                                <li>1 Mistura para bolo de coco;</li>
                                <li>1 Embalagem de doce de leite Milk Menk;</li>
                                <li>1 Pacote de coco ralado (100g);</li>
                                <li>2 Xícaras de chá de leite Milk Menk;</li>
                                <li>1 Garrafa pequena de leite de coco (200 ml);</li>
                                <li>100 ml de água;</li>
                            </ul>
                        </div>
                        <div className="modo-preparo">
                            <h1>Mode de preparo:</h1>
                            <ul>
                                <li>Faça o bolo como pede o rótulo da embalagem.</li>
                                <li>Coloque no forno para assar. Hidrate o coco ralado em 100 ml de água. É só misturá-los. (Faço isso para o coco não ficar tão seco)</li>
                                <li>Após retirar o bolo do forno, ainda morno, desenforme em uma bandeja. E Corte o bolo ao meio.</li>
                                <li>Em uma jarra, misture o leite de coco com o leite Milk Menk.</li>
                                <li>Coloque metade do bolo cortado de volta para o tabuleiro, e molhe o bolo com metade da mistura de leite de coco com o leite Milk Menk.Espalhe um pouco do coco ralado. (Guarde a maior parte para colocar por cima do bolo)</li>
                                <li>Passe o recheio de doce de leite no bolo. Se quiser misture um pouco de creme de leite Milk Menk ao doce, assim fica mais fácil na hora de espalhar.</li>
                                <li>Coloque a outra metade do bolo e molhe com o restante da mistura de leite de coco e leite Milk Menk.</li>
                                <li>Coloque o restante coco ralado hidratado por cima do bolo. Deixe na geladeira por 6 horas.</li>
                            </ul>
                        </div>
                    </div> */}

                    <div className="item">
                        <div className="item-img">
                            <div className="titulo-receita">
                                <h1>Bolo Milk Menk</h1>
                            </div>
                            <img src={bolo} alt="" />
                        </div>
                        <div className="ingredientes">
                            <h1>Ingredientes:</h1>
                            <ul>
                                <li>1 Mistura para bolo de coco;</li>
                                <li>1 Embalagem de doce de leite Milk Menk;</li>
                                <li>1 Pacote de coco ralado (100g);</li>
                                <li>2 Xícaras de chá de leite Milk Menk;</li>
                                <li>1 Garrafa pequena de leite de coco (200 ml);</li>
                                <li>100 ml de água;</li>
                            </ul>
                        </div>
                        <div className="modo-preparo">
                            <h1>Mode de preparo:</h1>
                            <ul>
                                <li>Faça o bolo como pede o rótulo da embalagem.</li>
                                <li>Coloque no forno para assar. Hidrate o coco ralado em 100 ml de água. É só misturá-los. (Faço isso para o coco não ficar tão seco)</li>
                                <li>Após retirar o bolo do forno, ainda morno, desenforme em uma bandeja. E Corte o bolo ao meio.</li>
                                <li>Em uma jarra, misture o leite de coco com o leite Milk Menk.</li>
                                <li>Coloque metade do bolo cortado de volta para o tabuleiro, e molhe o bolo com metade da mistura de leite de coco com o leite Milk Menk.Espalhe um pouco do coco ralado. (Guarde a maior parte para colocar por cima do bolo)</li>
                                <li>Passe o recheio de doce de leite no bolo. Se quiser misture um pouco de creme de leite Milk Menk ao doce, assim fica mais fácil na hora de espalhar.</li>
                                <li>Coloque a outra metade do bolo e molhe com o restante da mistura de leite de coco e leite Milk Menk.</li>
                                <li>Coloque o restante coco ralado hidratado por cima do bolo. Deixe na geladeira por 6 horas.</li>
                            </ul>
                        </div>
                    </div>


                    <div className="item">
                        <div className="item-img">
                            <div className="titulo-receita">
                                <h1>Batata Gratinada na Manteiga</h1>
                            </div>
                            <img src={batata} alt="" />
                        </div>
                        <div className="ingredientes">
                            <h1>Ingredientes:</h1>
                            <ul>
                                <li>4 Batatas;</li>
                                <li>3 Colheres de queijo Milk Menk picado ou ralado;</li>
                                <li>2 Colheres de manteiga Milk Menk;</li>
                                <li>Sál a gosto;</li>
                                <li>1 Ramo de alecrim;</li>
                                <li>Água suficiente para cozinhar as batatas;</li>
                            </ul>
                        </div>
                        <div className="modo-preparo">
                            <h1>Mode de preparo:</h1>
                            <ul>
                                <li>Cozinhe as batatas com água e sal, descasque-as ainda quente.</li>
                                <li>Na frigideira adicione manteiga Milk Menk, leve ao fogo, acrescente as batatas cortadas e deixe ficar douradinhas.</li>
                                <li>Acrescente o alecrim e desligue o fogo</li>
                            </ul>
                        </div>
                    </div>


                    <div id="Mauricio" className="item">
                        <div className="item-img">
                            <div className="titulo-receita">
                                <h1>Pão de Queijo com Creme de Leite</h1>
                            </div>
                            <img src={pao} alt="" />
                        </div>
                        <div className="ingredientes">
                            <h1>Ingredientes:</h1>
                            <ul>
                                <li>1 Copo de requeijão Milk Menk;</li>
                                <li>1 Copo de requeijão mussarela ralada;</li>
                                <li>1 Copo de requeijão de polvilho doce;</li>
                                <li>250 ml de creme de leite Milk Menk;</li>
                            </ul>
                        </div>
                        <div className="modo-preparo">
                            <h1>Mode de preparo:</h1>
                            <ul>
                                <li>Para fazer esse pão de queijo com creme de leite Milk Menk super fácil, comece por misturar todos os ingredientes em uma tigela. Misture bem até obter uma massa homogênea que não gruda nas mãos</li>
                                <li>Enrole as bolinhas do pão de queijo e coloque sobre uma assadeira untada com manteiga Milk Menk. Asse em forno médio preaquecido até o pão de queijo com crede de leite Milk Menk ficar bem douradinho.</li>
                                <li>Seu pão de queijo com creme de leite está pronto a servir! Dica: Se desejar, pode congelar parte da massa.</li>
                            </ul>
                        </div>
                    </div>
            </div>
        </section>
    )
}

export default Receitas