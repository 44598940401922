const Coalhada = () => {
    return(
        <div className='cardContainer'>
            <div className='box'>

                <div className="card">
                    <div className="info">
                        <div className="name">Fresca</div>
                        <div className="infoName">Coalhada</div>
                        <div className="divider"></div>
                        <div className="bio">Produzida por fermentação do leite com cepas  de fermento especiais importado, conferindo uma consistência colherável, sabor levemente ácido e de fácil digestão.</div>
                        {/* <button className='botaoContato'>Contate-nos</button> */}
                    </div>
                    <div className="photo CoalhadaFresca"></div>
                    <a className='botaoSaibaMais' href="https://api.whatsapp.com/send/?phone=5515998194162" target="_blank">Saiba Mais</a>
                </div>

                <div className="card">
                    <div className="info">
                        <div className="name">Seca</div>
                        <div className="infoName">Coalhada</div>
                        <div className="divider"></div>
                        <div className="bio">Receita original Labneh, produzido artesanalmente sob rígido controle de qualidade, onde é feito a extração do soro após a fermentação do leite, resultando em uma coalhada cremosa, levemente ácida e amanteigada. </div>
                        {/* <button className='botaoContato'>Contate-nos</button> */}
                    </div>
                    <div className="photo CoalhadaSeca"></div>
                    <a className='botaoSaibaMais' href="https://api.whatsapp.com/send/?phone=5515998194162" target="_blank">Saiba Mais</a>
                </div>

                <div className="card">
                    <div className="info">
                        <div className="name">Polpa de Morango</div>
                        <div className="infoName">Coalhada</div>
                        <div className="divider"></div>
                        <div className="bio">A mesma coalhada fresca com uma camada superior de preparado de frutas em pedaços, oferecendo um agradável contraste de sabor levemente ácido da coalhada com o adocicado do preparado de frutas em pedaços. </div>
                        {/* <button className='botaoContato'>Contate-nos</button> */}
                    </div>
                    <div className="photo CoalhadaMorango"></div>
                    <a className='botaoSaibaMais' href="https://api.whatsapp.com/send/?phone=5515998194162" target="_blank">Saiba Mais</a>
                </div>

                <div className="card">
                    <div className="info">
                        <div className="name">Polpa de Abacaxi</div>
                        <div className="infoName">Coalhada</div>
                        <div className="divider"></div>
                        <div className="bio">A mesma coalhada fresca com uma camada superior de preparado de frutas em pedaços, oferecendo um agradável contraste de sabor levemente ácido da coalhada com o adocicado do preparado de frutas em pedaços. </div>
                        {/* <button className='botaoContato'>Contate-nos</button> */}
                    </div>
                    <div className="photo CoalhadaAbacaxi"></div>
                    <a className='botaoSaibaMais' href="https://api.whatsapp.com/send/?phone=5515998194162" target="_blank">Saiba Mais</a>
                </div>

                <div className="card">
                    <div className="info">
                        <div className="name">Polpa de Ameixa</div>
                        <div className="infoName">Coalhada</div>
                        <div className="divider"></div>
                        <div className="bio">A mesma coalhada fresca com uma camada superior de preparado de frutas em pedaços, oferecendo um agradável contraste de sabor levemente ácido da coalhada com o adocicado do preparado de frutas em pedaços. </div>
                        {/* <button className='botaoContato'>Contate-nos</button> */}
                    </div>
                    <div className="photo CoalhadaAmeixa"></div>
                    <a className='botaoSaibaMais' href="https://api.whatsapp.com/send/?phone=5515998194162" target="_blank">Saiba Mais</a>
                </div>

                <div className="card">
                    <div className="info">
                        <div className="name">Polpa de Pêssego</div>
                        <div className="infoName">Coalhada</div>
                        <div className="divider"></div>
                        <div className="bio">A mesma coalhada fresca com uma camada superior de preparado de frutas em pedaços, oferecendo um agradável contraste de sabor levemente ácido da coalhada com o adocicado do preparado de frutas em pedaços. </div>
                        {/* <button className='botaoContato'>Contate-nos</button> */}
                    </div>
                    <div className="photo CoalhadaPessego"></div>
                    <a className='botaoSaibaMais' href="https://api.whatsapp.com/send/?phone=5515998194162" target="_blank">Saiba Mais</a>
                </div>

                <div className="card">
                    <div className="info">
                        <div className="name">Polpa de Maracujá</div>
                        <div className="infoName">Coalhada</div>
                        <div className="divider"></div>
                        <div className="bio">A mesma coalhada fresca com uma camada superior de preparado de frutas em pedaços, oferecendo um agradável contraste de sabor levemente ácido da coalhada com o adocicado do preparado de frutas em pedaços. </div>
                        {/* <button className='botaoContato'>Contate-nos</button> */}
                    </div>
                    <div className="photo CoalhadaMaracuja"></div>
                    <a className='botaoSaibaMais' href="https://api.whatsapp.com/send/?phone=5515998194162" target="_blank">Saiba Mais</a>
                </div>

            </div>
        </div>
    )
}

export default Coalhada