import './ParceirosCidades.css'
import Potiguara from './Images/Potiguara.png'
import EmporioDaFazenda from './Images/EmporioDaFazenda.png'
import DeliciasDaRoca from './Images/DeliciasDaRoca.png'

const Itu = () => {
    return (
        <section class="containerMM">
            <div class="nossasLojasMM">

                <div class="conteudoMM">
                    <div class="mapaMM">
                        <img className='localImg' src={EmporioDaFazenda} alt='EMPÓRIO DA FAZENDA'/>
                        <iframe
                            title='EMPÓRIO DA FAZENDA'
                            height="300"
                            width="100%"
                            style={{ border: 0, borderRadius: '25px 25px 0px 0px' }}
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3664.969643787221!2d-47.2853825!3d-23.2805527!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94cf4551b6ac8fd5%3A0xaa2c630fca367974!2sR.%20Bartira%2C%20162%20-%20Vila%20Leis%2C%20Itu%20-%20SP%2C%2013309-210!5e0!3m2!1spt-BR!2sbr!4v1702652649942!5m2!1spt-BR!2sbr"
                            allowfullscreen
                        ></iframe>
                    </div>

                    <div class="descricaoMM">
                        <h3 class="h3MM">EMPÓRIO DA FAZENDA</h3>

                        <div>
                            <p class="pMM">R. Bartira, 162<br />
                                Vila Leis - ITU/SP - 13309-210</p>

                            <p class="pMM">TEL: (11) 99636-0254</p>
                        </div>
                    </div>
                </div>

                <div class="conteudoMM">
                    <div class="mapaMM">
                        <img className='localImg' src={Potiguara} alt='SUPERMERCADO POTIGUARA'/>
                        <iframe
                            title='SUPERMERCADO POTIGUARA'
                            height="300"
                            width="100%"
                            style={{ border: 0, borderRadius: '25px 25px 0px 0px' }}
                            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14662.394541011832!2d-47.3536687!3d-23.2576882!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94cf50df77311ba7%3A0xe86a5733ad866e27!2sSupermercado%20Potiguara!5e0!3m2!1spt-BR!2sbr!4v1709671708579!5m2!1spt-BR!2sbr"
                            allowfullscreen
                        ></iframe>
                    </div>

                    <div class="descricaoMM">
                        <h3 class="h3MM">SUPERMERCADO POTIGUARA</h3>

                        <div>
                            <p class="pMM">R. Hamleto Menquini, 41<br />
                                Parque Res. Potiguara - ITU/SP - 13312-714</p>

                            {/* <p class="pMM">TEL: (11) 99636-0254</p> */}
                        </div>
                    </div>
                </div>

                <div class="conteudoMM">
                    <div class="mapaMM">
                        {/* <img className='localImg' src={} /> */}
                        <iframe
                            title='PANIFICADORA LIDER'
                            height="300"
                            width="100%"
                            style={{ border: 0, borderRadius: '25px 25px 0px 0px' }}
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3665.684312477546!2d-47.3079147!3d-23.254572099999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94cf5039695d6461%3A0x2fd8dac2eb73b864!2sR.%20Ign%C3%A1cio%20Rodrigues%20D&#39;Avila%2C%20268%20-%20Vila%20Padre%20Bento%2C%20Itu%20-%20SP%2C%2013313-020!5e0!3m2!1spt-BR!2sbr!4v1709818124465!5m2!1spt-BR!2sbr"
                            allowfullscreen
                        ></iframe>
                    </div>

                    <div class="descricaoMM">
                        <h3 class="h3MM">PANIFICADORA LÍDER</h3>

                        <div>
                            <p class="pMM">R. Ignácio Rodrigues D'Avila, 268<br />
                                Vila Padre Bento - ITU/SP - 13313-020</p>

                            {/* <p class="pMM">TEL: (11) 99636-0254</p> */}
                        </div>
                    </div>
                </div>

                <div class="conteudoMM">
                    <div class="mapaMM">
                        <img className='localImg' src={DeliciasDaRoca} alt='DELICIAS DA ROÇA' />
                        <iframe
                            title='DELICIAS DA ROÇA'
                            height="300"
                            width="100%"
                            style={{ border: 0, borderRadius: '25px 25px 0px 0px' }}
                            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14661.90001618619!2d-47.3064128!3d-23.262184!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94cf5016651c37e7%3A0x652b0d3c82939949!2sDel%C3%ADcias%20da%20Ro%C3%A7a%20de%20Minas!5e0!3m2!1spt-BR!2sbr!4v1709818275654!5m2!1spt-BR!2sbr"
                            allowfullscreen
                        ></iframe>
                    </div>

                    <div class="descricaoMM">
                        <h3 class="h3MM">DELÍCIAS DA ROÇA</h3>

                        <div>
                            <p class="pMM">Av. Galileu Bicudo, 538<br />
                                Jardim Santana - ITU/SP - 13312-330</p>

                            {/* <p class="pMM">TEL: (11) 99636-0254</p> */}
                        </div>
                    </div>
                </div>


            </div>
        </section>

    )
}

export default Itu