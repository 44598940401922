import historia from './historia.jpg'
import './Historia.css'

const Historia = () => {
    return(
        <section id='Historia'>
            <div className='historia-container'>
                <div className="titulo-historia">
                    <h1>História</h1>
                </div>
                <div className='historia'>
                    <div className='historia-img'>
                        <img src={historia} alt=''/>
                    </div>
                    <div className='historia-text'>
                        <p>“Essa linda jornada da qual tenho muito orgulho em compartilhar se iniciou em 1986, na crise do governo Sarnei, quando o preço do leite ficou defasado em 42% e momentaneamente sumiu de todo o mercado brasileiro.</p>

                        <p>Na época minha sobrinha ainda era muito pequena, tinha 1 ano de idade e necessitava ter o leite como refeição para seu desenvolvimento, e para que o mesmo não faltasse, minha irmã vinha até a fazenda buscar das vacas que eu possuía na época para levar para sua filha. Mas aos poucos os vizinhos, amigos e conhecidos começaram a pedir para que ela trouxesse para eles também. Foi aí que ela viu uma oportunidade de negócio e fez dessa oportunidade o seu trabalho.</p>

                        <p>As coisas foram se desenvolvendo aos poucos, eu fornecendo e minha irmã distribuindo até que um tempo depois que já estávamos com um bom número de clientes, vendendo o leite cru em garrafas pet, um amigo de longa data me trouxe um pequeno recorte de jornal de uma propaganda de uma máquina que ensacava leite e me disse “Por que você não investe nisso Zé? Dessa forma seu leite pode ser conservado de forma mais higiênica e agradará muito mais a sua clientela. ”</p>

                        <p>Pensei muito sobre o que ele me disse e decidi comprar. Quando cheguei até lá o dono da empresa me ofereceu também um pasteurizador, mostrando o mundo de oportunidades que poderiam surgir após o investimento nessas duas maquinas. E de fato eles estavam certos... Mas para que eu pudesse comprar tudo aquilo tive que vender um terreno que possuía pelo valor exato que eu deveria investir nas maquinas. Foi aí então que surgiu a Milk Menk Laticínios.</p>

                        <p>Decidi colocar o meu próprio sobrenome “Menk” na minha empresa porque sempre acreditei no potencial e qualidade do meu produto.</p>

                        <p>Em 1994 decidi aumentar a minha linha de produtos e eu mesmo ia até o instituto lácteo em outra cidade para aprender a fazer derivados com profissionais. Lá eu aprendi, inovei e aprimorei a receita de todos os meus produtos, voltei e ensinei meus colaboradores a replicar essa fórmula de sucesso.</p>

                        <p>Com o laticínio crescendo cada vez mais, cresceu também a minha responsabilidade social com o meio ambiente e desde então tenho como objetivo ter uma fazenda totalmente sustentável. Hoje 90% da fazenda é sustentável e nosso objetivo é de até em 2020 a fazenda passar a ser 100% sustentável.</p>

                        <p>Hoje é fácil olhar para toda grandiosidade da Milk Menk e ficar feliz, difícil mesmo é lembrar de toda essa árdua trajetória sem se orgulhar das minhas decisões.</p>

                        <p className='author'>José Alberto Paiffer Menk.</p>

                    </div>
                </div>
            </div>
        </section>
    )
}

export default Historia