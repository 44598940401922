import './ParceirosCidades.css'
import LaDaRoca from './Images/LaDaRoca.png'

const CesarioLange = () => {
    return (
        <section class="containerMM">
            <div class="nossasLojasMM" style={{ gridTemplateColumns: "1fr"}}>

                <div class="conteudoMM">
                    <div class="mapaMM">
                        <img className='localImg' src={LaDaRoca} alt='LÁ DA ROÇA'/>
                        <iframe
                            title='LÁ DA ROÇA'
                            height="300"
                            width="100%"
                            style={{ border: 0, borderRadius: '25px 25px 0px 0px' }}
                            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14666.19322289355!2d-47.9573337!3d-23.2231264!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94c67b5e4767142d%3A0x41f184c06e34bb99!2zTMOhIGRhIHJvw6dh!5e0!3m2!1spt-BR!2sbr!4v1715866414134!5m2!1spt-BR!2sbr"
                            allowfullscreen
                        ></iframe>
                    </div>

                    <div class="descricaoMM">
                        <h3 class="h3MM">LÁ DA ROÇA</h3>

                        <div>
                            <p class="pMM">Av. 3 de Maio, 1306<br />
                                Centro - CESÁRIO LANGE/SP - 18285-000</p>

                            <p class="pMM">TEL: (11) 94310-5491</p>
                        </div>
                    </div>
                </div>

            </div>
        </section>

    )
}

export default CesarioLange