import './ParceirosCidades.css'
import LaDaRoca from './Images/LaDaRoca.png'

const Indaiatuba = () => {
    return (
        <section class="containerMM">
            <div class="nossasLojasMM" style={{ gridTemplateColumns: "1fr"}}>

                <div class="conteudoMM">
                    <div class="mapaMM">
                        {/* <img className='localImg' src={LaDaRoca} alt='EMPÓRIUM NORTE SUL'/> */}
                        <iframe
                            title='EMPÓRIUM NORTE SUL'
                            height="300"
                            width="100%"
                            style={{ border: 0, borderRadius: '25px 25px 0px 0px' }}
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3669.426164535825!2d-47.233662599999995!3d-23.118093100000003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94cf4cbf7e77d287%3A0xcdb551cdf65a078c!2sAv.%20Eng.%20F%C3%A1bio%20Roberto%20Barnab%C3%A9%2C%205838%20-%20Jardim%20Morada%20do%20Sol%2C%20Indaiatuba%20-%20SP%2C%2013348-385!5e0!3m2!1spt-BR!2sbr!4v1724852723512!5m2!1spt-BR!2sbr"
                            allowfullscreen
                        ></iframe>
                    </div>

                    <div class="descricaoMM">
                        <h3 class="h3MM">EMPÓRIUM NORTE SUL</h3>

                        <div>
                            <p class="pMM">Av Engen. Fábio Roberto Barnabé, 5838 Sala2<br />
                                Jd. Morada do Sol - INDAIATUBA/SP - 13.346-443</p>

                            <p class="pMM">TEL: (19) 98205-0056 - Sérgio</p>
                            <p class="pMM">TEL: (11) 93363-8588 - Wesley</p>
                        </div>
                    </div>
                </div>

            </div>
        </section>

    )
}

export default Indaiatuba