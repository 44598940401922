import './ParceirosCidades.css'
import CasaDoPaoCaseiro from './Images/CasaDoPaoCaseiroEbenezer.png'
import OPaoDaVizinha from './Images/OPaoDaVizinha.png'
import PadariaMercadoCosta from './Images/PadariaMercadoCosta.png'
import MercadoAlmeida from './Images/MercadoAlmeida.png'

const Sorocaba = () => {
    return (
        <section class="containerMM">
            <div class="nossasLojasMM">

                <div class="conteudoMM">
                    <div class="mapaMM">
                        <img className='localImg' src={CasaDoPaoCaseiro} alt='CASA DO PÃO CASEIRO EBENEZER' />
                        <iframe
                            title='CASA DO PÃO CASEIRO EBENEZER'
                            height="300"
                            width="100%"
                            style={{ border: 0, borderRadius: '25px 25px 0px 0px' }}
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3658.648323012604!2d-47.5131783!3d-23.5091733!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94c58b4ece1f9891%3A0xe7ea7ed1d113f676!2sR.%20Jo%C3%A3o%20Martins%20Foga%C3%A7a%2C%20287%20-%20Jardim%20Piazza%20Di%20Roma%2C%20Sorocaba%20-%20SP%2C%2018051-827!5e0!3m2!1spt-BR!2sbr!4v1705680237048!5m2!1spt-BR!2sbr"
                            allowfullscreen
                        ></iframe>
                    </div>

                    <div class="descricaoMM">
                        <h3 class="h3MM">CASA DO PÃO CASEIRO EBENEZER</h3>

                        <div>
                            <p class="pMM" style={{maxWidth: "260px"}}>R. João Martins Fogaça, 287<br />
                                Jardim Piazza Di Roma - SOROCABA/SP - 18051-827</p>

                            {/* <p class="pMM" style={{maxWidth: "260px"}}>TEL: (11) 99636-????</p> */}
                        </div>
                    </div>
                </div>

                <div class="conteudoMM">
                    <div class="mapaMM">
                        {/* <img className='localImg' src='https://prod-ripcut-delivery.disney-plus.net/v1/variant/disney/46C686E3B18E2FB101C906C29D45E4832C30868A71E5EE4AAF0C9E0835229C05/scale?width=1200&aspectRatio=1.78&format=webp' /> */}
                        <iframe
                            title='TITA ROTISSERIE E EMPÓRIO'
                            height="300"
                            width="100%"
                            style={{ border: 0, borderRadius: '25px 25px 0px 0px' }}
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3658.6229646499232!2d-47.4643438!3d-23.5100862!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94c58a9407d0e53d%3A0xb62673240fa4fb7f!2sRua%20Dr.%20Francisco%20Prestes%20Maia%2C%20171%20-%20Vila%20Florinda%2C%20Sorocaba%20-%20SP%2C%2018040-650!5e0!3m2!1spt-BR!2sbr!4v1705680594056!5m2!1spt-BR!2sbr"
                            allowfullscreen
                        ></iframe>
                    </div>

                    <div class="descricaoMM">
                        <h3 class="h3MM">TITA ROTISSERIE & EMPÓRIO</h3>

                        <div>
                            <p class="pMM" style={{maxWidth: "220px"}}>Rua Dr. Francisco Prestes Maia, 171<br />
                                Vila Florinda - SOROCABA/SP - 18040-650</p>

                            <p class="pMM">TEL: (15) 99713-6353</p>
                        </div>
                    </div>
                </div>

                <div class="conteudoMM">
                    <div class="mapaMM">
                        {/* <img className='localImg' src='https://prod-ripcut-delivery.disney-plus.net/v1/variant/disney/46C686E3B18E2FB101C906C29D45E4832C30868A71E5EE4AAF0C9E0835229C05/scale?width=1200&aspectRatio=1.78&format=webp' /> */}
                        <iframe
                            title='CASA DOS OVOS DEUS É BOM'
                            height="300"
                            width="100%"
                            style={{ border: 0, borderRadius: '25px 25px 0px 0px' }}
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3661.8209549915478!2d-47.37810409999999!3d-23.3946936!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94cf5ea48037843b%3A0xa45ab481be4dee9a!2sRua%20Juvenal%20de%20Paula%20Souza%2C%20346%2C%20Sorocaba%20-%20SP%2C%2018105-180!5e0!3m2!1spt-BR!2sbr!4v1705680970004!5m2!1spt-BR!2sbr"
                            allowfullscreen
                        ></iframe>
                    </div>

                    <div class="descricaoMM">
                        <h3 class="h3MM">CASA DOS OVOS DEUS É BOM</h3>

                        <div>
                            <p class="pMM" style={{maxWidth: "220px"}}>R. Juvenal de Paula Souza, 346<br />
                                Novo Cajuru - SOROCABA/SP - 18105-180</p>

                            <p class="pMM">TEL: (15) 99608-9429</p>
                        </div>
                    </div>
                </div>

                <div class="conteudoMM">
                    <div class="mapaMM">
                        <img className='localImg' src={OPaoDaVizinha} alt='O PÃO DA VIZINHA' />
                        <iframe
                            title='O PAO DA VIZINHA'
                            height="300"
                            width="100%"
                            style={{ border: 0, borderRadius: '25px 25px 0px 0px' }}
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3658.7681994642903!2d-47.4480442!3d-23.504857299999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94cf60274b7c9901%3A0xb216ef0b516f26f4!2sR.%20Tereza%20Lopes%2C%2030%20-%20Vila%20Hort%C3%AAncia%2C%20Sorocaba%20-%20SP%2C%2018020-224!5e0!3m2!1spt-BR!2sbr!4v1709820737388!5m2!1spt-BR!2sbr"
                            allowfullscreen
                        ></iframe>
                    </div>

                    <div class="descricaoMM">
                        <h3 class="h3MM">O PÃO DA VIZINHA</h3>

                        <div>
                            <p class="pMM">R. Cel. Nogueira Padilha, 537<br />
                                Vila Hortência - SOROCABA/SP - 18020-000</p>

                            <p class="pMM">TEL: (15) 98139-2273</p>
                        </div>
                    </div>
                </div>

                <div class="conteudoMM">
                    <div class="mapaMM">
                        {/* <img className='localImg' src={} /> */}
                        <iframe
                            title='FEIRA LIVRE'
                            height="300"
                            width="100%"
                            style={{ border: 0, borderRadius: '25px 25px 0px 0px' }}
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3658.7606536373455!2d-47.4667135!3d-23.505129!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94c58abfa3516eab%3A0xf7789ae88561c917!2sR.%20Francisco%20Ferreira%20Le%C3%A3o%20-%20Vila%20Leao%2C%20Sorocaba%20-%20SP%2C%2018040-429!5e0!3m2!1spt-BR!2sbr!4v1709821292795!5m2!1spt-BR!2sbr"
                            allowfullscreen
                        ></iframe>
                    </div>

                    <div class="descricaoMM">
                        <h3 class="h3MM">FEIRA LIVRE - BANCA DOS FRIOS DO JUNIOR</h3>

                        <div>
                            <p class="pMM"><b>Quinta-feira:</b><br/> R. Francisco Ferreira Leão <br />
                                Vila Leão  - SOROCABA/SP - 18040-429</p>

                            <p class="pMM"><b>Das 06:00 às 12:00</b></p>
                        </div>
                    </div>
                </div>

                <div class="conteudoMM">
                    <div class="mapaMM">
                        {/* <img className='localImg' src={} /> */}
                        <iframe
                            title='FEIRA LIVRE'
                            height="300"
                            width="100%"
                            style={{ border: 0, borderRadius: '25px 25px 0px 0px' }}
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3658.7417623251563!2d-47.476749100000006!3d-23.505809199999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94c58ac3c579a9d9%3A0xa5b6cf90edd7990a!2sR.%20Bar%C3%A3o%20de%20Cotegipe%20-%20Vila%20Jardini%2C%20Sorocaba%20-%20SP%2C%2018040-420!5e0!3m2!1spt-BR!2sbr!4v1709821411239!5m2!1spt-BR!2sbr"
                            allowfullscreen
                        ></iframe>
                    </div>

                    <div class="descricaoMM">
                        <h3 class="h3MM">FEIRA LIVRE - BANCA DOS FRIOS DO JUNIOR</h3>

                        <div>
                            <p class="pMM"><b>Domingo:</b><br/> R. Barão de Cotegipe <br />
                                Vila Jardini  - SOROCABA/SP - 18040-420</p>

                            <p class="pMM"><b>Das 06:00 às 12:00</b></p>
                        </div>
                    </div>
                </div>

                <div class="conteudoMM">
                    <div class="mapaMM">
                        <img className='localImg' src={PadariaMercadoCosta} alt='PADARIA E MERCADO COSTA'/>
                        <iframe
                            title='PADARIA E MERCADO COSTA'
                            height="300"
                            width="100%"
                            style={{ border: 0, borderRadius: '25px 25px 0px 0px' }}
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3660.737593906584!2d-47.5113715!3d-23.433844399999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94c5f419c30c3505%3A0xca290f6e8fec2502!2sR.%20Lib%C3%B3rio%20Monaldo%20Stilitano%2C%20206%20-%20Parque%20S%C3%A3o%20Bento%2C%20Sorocaba%20-%20SP%2C%2018072-400!5e0!3m2!1spt-BR!2sbr!4v1709821860792!5m2!1spt-BR!2sbr"
                            allowfullscreen
                        ></iframe>
                    </div>

                    <div class="descricaoMM">
                        <h3 class="h3MM">PADARIA E MERCADO COSTA</h3>

                        <div>
                            <p class="pMM">R. Libório Monaldo Stilitano, 206 <br />
                                Parque São Bento  - SOROCABA/SP - 18072-400</p>
                        </div>
                    </div>
                </div>

                <div class="conteudoMM">
                    <div class="mapaMM">
                        <img className='localImg' src={MercadoAlmeida} alt='MERCADO ALMEIDA' />
                        <iframe
                            title='MERCADO ALMEIDA'
                            height="300"
                            width="100%"
                            style={{ border: 0, borderRadius: '25px 25px 0px 0px' }}
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3660.749009000035!2d-47.5129531!3d-23.4334322!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94c5f41a27abb623%3A0x3ea9ac94dc3e496d!2sR.%20Alcino%20Oliveira%20Rosa%2C%201116%20-%20Parque%20S%C3%A3o%20Bento%2C%20Sorocaba%20-%20SP%2C%2018072-660!5e0!3m2!1spt-BR!2sbr!4v1709822024158!5m2!1spt-BR!2sbr"
                            allowfullscreen
                        ></iframe>
                    </div>

                    <div class="descricaoMM">
                        <h3 class="h3MM">MERCADO ALMEIDA</h3>

                        <div>
                            <p class="pMM">R. Alcino Oliveira Rosa, 1116 <br />
                                Parque São Bento  - SOROCABA/SP - 18072-660</p>
                        </div>
                    </div>
                </div>

                <div class="conteudoMM">
                    <div class="mapaMM">
                        {/* <img className='localImg' src={} /> */}
                        <iframe
                            title='FEIRA LIVRE'
                            height="300"
                            width="100%"
                            style={{ border: 0, borderRadius: '25px 25px 0px 0px' }}
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3660.1283813715886!2d-47.4711274!3d-23.455833199999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94c5f50c620511c5%3A0x210679a8a4b1c16f!2sR.%20Benedita%20Concei%C3%A7%C3%A3o%20Gomes%20Perbelini%2C%20Sorocaba%20-%20SP%2C%2018077-110!5e0!3m2!1spt-BR!2sbr!4v1709822614693!5m2!1spt-BR!2sbr"
                            allowfullscreen
                        ></iframe>
                    </div>

                    <div class="descricaoMM">
                        <h3 class="h3MM">FEIRA LIVRE - VALDECI BARRACA DO QUEIJO</h3>

                        <div>
                            <p class="pMM"><b>Terça-Feira:</b><br/> R. Benedita Conceição Gomes Perbelini <br />
                                Jardim Guaíba  - SOROCABA/SP - 18077-110</p>

                            <p class="pMM"><b>Das 16:00 às 21:00</b></p>
                        </div>
                    </div>
                </div>

                <div class="conteudoMM">
                    <div class="mapaMM">
                        {/* <img className='localImg' src={} /> */}
                        <iframe
                            title='FEIRA LIVRE'
                            height="300"
                            width="100%"
                            style={{ border: 0, borderRadius: '25px 25px 0px 0px' }}
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3659.5234014243056!2d-47.5086157!3d-23.47765!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94c5f494c3ed3b4b%3A0x1e54ac2b51f3a0e6!2sR.%20Fernando%20Antonio%20Guerner%20Camargo%2C%20Sorocaba%20-%20SP%2C%2018057-081!5e0!3m2!1spt-BR!2sbr!4v1709822580975!5m2!1spt-BR!2sbr"
                            allowfullscreen
                        ></iframe>
                    </div>

                    <div class="descricaoMM">
                        <h3 class="h3MM">FEIRA LIVRE - VALDECI BARRACA DO QUEIJO</h3>

                        <div>
                            <p class="pMM"><b>Quinta-Feira:</b><br/> R. Fernando Antonio Guerner Camargo <br />
                                Jardim Wanel Ville V  - SOROCABA/SP - 18057-081</p>

                            <p class="pMM"><b>Das 16:00 às 21:00 - Barraca 87/88</b></p>
                        </div>
                    </div>
                </div>


            </div>
        </section>

    )
}

export default Sorocaba