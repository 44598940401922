import { FaCheck } from "react-icons/fa";
import Check from './check.png'
import { Link } from 'react-router-dom'
import './EnviadoComSucesso.css'

const EnviadoComSucesso = () => {
    return(
        <section className="EnvioEmail">
            <div className="ContainerEnvio">
                <FaCheck className="iconCheck" />
                <h1>E-mail enviado com Sucesso!</h1>
                <img src={Check} alt="Ok" />
                <h3>Sua mensagem foi enviada com sucesso!</h3>
                <h5>Aguarde que em breve entraremos em contato.</h5>
                <Link to="/"><button>OK</button></Link>
            </div>
        </section>
    )
}

export default EnviadoComSucesso