import './FAQ.css'

const PerguntasRespostas = () => {
    return(
        <section className="FAQ">
            <div class="container-faq">
        <div class="tab">
            <input type="radio" name="acc" id="acc1"/>
            <label for="acc1">
                <h2>01</h2>
                <h3>O leite tem contato humano?</h3>
            </label>
            <div class="content-faq">
                <p>Não, o leite em nenhum momento tem contato humano, assim que ele é extraido, ele é automaticamente enviado para análise e se aprovado é pasteurizado e em seguida distribuido para toda a produção.</p>
            </div>
        </div>

        <div class="tab">
            <input type="radio" name="acc" id="acc2"/>
            <label for="acc2">
                <h2>02</h2>
                <h3>O que é pasteurização do leite?</h3>
            </label>
            <div class="content-faq">
                <p>O leite Milk Menk é pasteurizado a 76°C, temperatura ideal que elimina as bactérias ruins, sem perder o valor nutricional e as bactérias "boas" para o nosso organismo. O processo de pasteurização consiste em submeter o alimento à uma alta temperatura e em seguida, submetê-lo em uma baixa temperatura.</p>
            </div>
        </div>

        <div class="tab">
            <input type="radio" name="acc" id="acc3"/>
            <label for="acc3">
                <h2>03</h2>
                <h3>O Iogurte tem frutas de verdade?</h3>
            </label>
            <div class="content-faq">
                <p>Sim, Iogurtes e Coalhadas recebem frutas saudáveis para consumo, deixando tudo ainda mais saboroso.</p>
            </div>
        </div>

        <div class="tab">
            <input type="radio" name="acc" id="acc4"/>
            <label for="acc4">
                <h2>04</h2>
                <h3>O Leite A2 é melhor que o Convencional?</h3>
            </label>
            <div class="content-faq">
                <p>Oque diferencia o leite A2 do convencional é apenas uma fração de proteína - a beta-caseína. No leite convencional, encontramos a beta-caseína A1, produzida por vacas com a genética A1. Já no leite A2, encontramos a beta caseina A2, produzida por vacas com o genótipo A2A2. Essa pequena diferença torna o leite mais fácil de digerir, por que? Durante a digestão, a beta caseína A2 é totalmente digerida por nosso organismo e não ocorre a formação do BCM-7, um peptídeo relacionado a ocorrência de desconfortos gastrointestinais. Mas, para que tenhamos certeza de quem estamos consumindo somente leite A2 é necessário que seja certificado! É por meio do selo na embalagem que a pureza, a origem e a rastreabilidade desse alimento é atestada!</p>
            </div>
        </div>

        <div class="tab">
            <input type="radio" name="acc" id="acc5"/>
            <label for="acc5">
                <h2>05</h2>
                <h3>A Milk Menk tem consciência sustentável?</h3>
            </label>
            <div class="content-faq">
                <p>Adotamos uma filosofia que nos enche de orgulho, a filosofia de consciência sustentável. No Free Stall, local onde nossas vacas ficam alojadas, existem raspadores automaticos que permanecem ligados 24h por dia, mantendo o ambiente limpo e confortável, além de coletar todos os dejetos do rebanho, levando imediatamente toda excreção até o separador.</p>
            </div>
        </div>

        <div class="tab">
            <input type="radio" name="acc" id="acc6"/>
            <label for="acc6">
                <h2>06</h2>
                <h3>Como é o cuidade com os animais?</h3>
            </label>
            <div class="content-faq">
                <p>Todos os animais recebem dieta balanceada, e as lactantes ficam em ambiente com temperatura controlada (toda vez que a temperatura ultrapassa 23 graus C os ventiladores são acionados automaticamente ), os animais também passam por uma aspersão de água para refrescar antes da ordenha. Dormem em camas individuais forradas com cerragem. O piso onde ficam é emborrachado e antiderrapante, para evitar quedas.</p>
            </div>
        </div>

        <div class="tab">
            <input type="radio" name="acc" id="acc7"/>
            <label for="acc7">
                <h2>07</h2>
                <h3>Os produtos da Milk Menk tem prazo de validade?</h3>
            </label>
            <div class="content-faq">
                <p>Sim, cada produto tem um prazo de validade conforme a lei ficando assim visível de fácil leitura ao consumidor.</p>
            </div>
        </div>

        <div class="tab">
            <input type="radio" name="acc" id="acc8"/>
            <label for="acc8">
                <h2>08</h2>
                <h3>Podemos visitar a fazenda?</h3>
            </label>
            <div class="content-faq">
                <p>Há possibilidade de visitas com finalidades educacional, desde sejam pré-agendadas.</p>
            </div>
        </div>

        <div class="tab">
            <input type="radio" name="acc" id="acc9"/>
            <label for="acc9">
                <h2>09</h2>
                <h3>Como faço para levar Milk Menk para minha cidade?</h3>
            </label>
            <div class="content-faq">
                <p>Entre em contato com nosso setor comercial pelos números: (15) 3262-9372 / (15) 99819-4162 whatsApp.</p>
            </div>
        </div>

    </div>
        </section>
    )
}

const FAQ = () => {
    return(
        <>
            <PerguntasRespostas />
        </>
    )
}

export default FAQ