
const Leite = () => {
    return(
        <div className='cardContainer'>
            <div className='box'>

                <div className="card">
                    <div className="info">
                        <div className="name">Integral</div>
                        <div className="infoName">Leite</div>
                        <div className="divider"></div>
                        <div className="bio">É o leite mais fresco, sem nenhum aditivo ou conservante, por isso dura menos. É pasteurizado a 76 graus, matando as bactérias ruins e mantendo as boas para nossa flora intestina. Sua gordura é padronizada a 3% e homogenizada  para a quebra em partículas menores, sendo de mais fácil digestão.</div>
                        {/* <button className='botaoContato'>Contate-nos</button> */}
                    </div>
                    <div className="photo LeiteIntegral"></div>
                    <a className='botaoSaibaMais' href="https://api.whatsapp.com/send/?phone=5515998194162" target="_blank">Saiba Mais</a>
                </div>

                <div className="card">
                    <div className="info">
                        <div className="name">A2</div>
                        <div className="infoName">Leite</div>
                        <div className="divider"></div>
                        <div className="bio">No leite convencional, encontramos a beta-caseína A1, produzida por vacas com a genética A1. Já no leite A2, encontramos a beta caseína A2, produzida por vacas com o genótipo A2A2. Essa pequena diferença torna o leite mais fácil de digerir.</div>
                        {/* <button className='botaoContato'>Contate-nos</button> */}
                    </div>
                    <div className="photo LeiteA2"></div>
                    <a className='botaoSaibaMais' href="https://api.whatsapp.com/send/?phone=5515998194162" target="_blank">Saiba Mais</a>
                </div>

            </div>
        </div>
    )
}

export default Leite