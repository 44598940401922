const bio = "Difere dos demais pela consistência muito mais cremosa, suave e encorpada, sem adição de espessantes, apenas devido a fermentação lenta de 13 horas,  com cepas novas de bactérias e uso somente de leite integral, SEM acréscimo de soro.  Além disso possui deliciosos pedaços de fruta naturais."
const Iogurte = () => {
    return(
        <div className='cardContainer'>
            <div className='box'>

                <div className="card">
                    <div className="info">
                        <div className="name">Abacaxi</div>
                        <div className="infoName">Iogurte</div>
                        <div className="divider"></div>
                        <div className="bio">{bio}</div>
                        {/* <button className='botaoContato'>Contate-nos</button> */}
                    </div>
                    <div className="photo IogurteAbacaxi"></div>
                    <a className='botaoSaibaMais' href="https://api.whatsapp.com/send/?phone=5515998194162" target="_blank">Saiba Mais</a>
                </div>

                <div className="card">
                    <div className="info">
                        <div className="name">Ameixa</div>
                        <div className="infoName">Iogurte</div>
                        <div className="divider"></div>
                        <div className="bio">{bio}</div>
                        {/* <button className='botaoContato'>Contate-nos</button> */}
                    </div>
                    <div className="photo IogurteAmeixa"></div>
                    <a className='botaoSaibaMais' href="https://api.whatsapp.com/send/?phone=5515998194162" target="_blank">Saiba Mais</a>
                </div>

                <div className="card">
                    <div className="info">
                        <div className="name">Coco</div>
                        <div className="infoName">Iogurte</div>
                        <div className="divider"></div>
                        <div className="bio">{bio}</div>
                        {/* <button className='botaoContato'>Contate-nos</button> */}
                    </div>
                    <div className="photo IogurteCoco"></div>
                    <a className='botaoSaibaMais' href="https://api.whatsapp.com/send/?phone=5515998194162" target="_blank">Saiba Mais</a>
                </div>

                <div className="card">
                    <div className="info">
                        <div className="name">Maracujá</div>
                        <div className="infoName">Iogurte</div>
                        <div className="divider"></div>
                        <div className="bio">{bio}</div>
                        {/* <button className='botaoContato'>Contate-nos</button> */}
                    </div>
                    <div className="photo IogurteMaracuja"></div>
                    <a className='botaoSaibaMais' href="https://api.whatsapp.com/send/?phone=5515998194162" target="_blank">Saiba Mais</a>
                </div>

                <div className="card">
                    <div className="info">
                        <div className="name">Morango</div>
                        <div className="infoName">Iogurte</div>
                        <div className="divider"></div>
                        <div className="bio">{bio}</div>
                        {/* <button className='botaoContato'>Contate-nos</button> */}
                    </div>
                    <div className="photo IogurteMorango"></div>
                    <a className='botaoSaibaMais' href="https://api.whatsapp.com/send/?phone=5515998194162" target="_blank">Saiba Mais</a>
                </div>

                <div className="card">
                    <div className="info">
                        <div className="name">Pêssego</div>
                        <div className="infoName">Iogurte</div>
                        <div className="divider"></div>
                        <div className="bio">{bio}</div>
                        {/* <button className='botaoContato'>Contate-nos</button> */}
                    </div>
                    <div className="photo IogurtePessego"></div>
                    <a className='botaoSaibaMais' href="https://api.whatsapp.com/send/?phone=5515998194162" target="_blank">Saiba Mais</a>
                </div>

                <div className="card">
                    <div className="info">
                        <div className="name">Natural</div>
                        <div className="infoName">Iogurte</div>
                        <div className="divider"></div>
                        <div className="bio"> Feito apenas com 2 ingredientes: leite desnatado a 1% de gordura e fermento lácteo, em um processo de fermentação lenta de 13 horas. Sem adição de soro de leite e outros adjuvantes.</div>
                        {/* <button className='botaoContato'>Contate-nos</button> */}
                    </div>
                    <div className="photo IogurteNatural"></div>
                    <a className='botaoSaibaMais' href="https://api.whatsapp.com/send/?phone=5515998194162" target="_blank">Saiba Mais</a>
                </div>

            </div>
        </div>
    )
}

export default Iogurte