
const DoceDeLeite = () => {
    return(
        <div className='cardContainer'>
            <div className='box'>

                <div className="card">
                    <div className="info">
                        <div className="name">Doce de Leite</div>
                        <div className="infoName">Milk Menk</div>
                        <div className="divider"></div>
                        <div className="bio">É um doce elaborado apenas com leite integral e uma quantidade de açúcar moderada, isso o torna com sabor equilibrado e não enjoativo. Tem consistência macia e cremosa, como o tradicional doce caseiro da fazenda, livres de espessantes e conservantes.</div>
                        {/* <button className='botaoContato'>Contate-nos</button> */}
                    </div>
                    <div className="photo DoceDeLeite"></div>
                    <a className='botaoSaibaMais' href="https://api.whatsapp.com/send/?phone=5515998194162" target="_blank">Saiba Mais</a>
                </div>

            </div>
        </div>
    )
}

export default DoceDeLeite