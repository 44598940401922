import './CardProdutos.css'
import Box from '../Box/Box'
const CardProdutos = () => {
    return(
        <>
        <Box />
        {/*<div className='cardContainer'>
            <div className='box'>

                <div className="card">
                    <div className="info">
                        <div className="name">Creme de Leite</div>
                        <div className="infoName">Milk Menk</div>
                        <div className="divider"></div>
                        <div className="bio">Lorem ipsum dolor site amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labora et dolore magna aliqua.</div>
                        <button className='botaoContato'>Contate-nos</button>
                    </div>
                    <div className="photo photo-1"></div>
                    <a className='botaoSaibaMais' href="#">Saiba Mais</a>
                </div>

                <div className="card">
                    <div className="info">
                        <div className="name">Creme de Leite</div>
                        <div className="infoName">Milk Menk</div>
                        <div className="divider"></div>
                        <div className="bio">Lorem ipsum dolor site amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labora et dolore magna aliqua.</div>
                        <button className='botaoContato'>Contate-nos</button>
                    </div>
                    <div className="photo photo-2"></div>
                    <a className='botaoSaibaMais' href="#">Saiba Mais</a>
                </div>

                <div className="card">
                    <div className="info">
                        <div className="name">Creme de Leite</div>
                        <div className="infoName">Milk Menk</div>
                        <div className="divider"></div>
                        <div className="bio">Lorem ipsum dolor site amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labora et dolore magna aliqua.</div>
                        <button className='botaoContato'>Contate-nos</button>
                    </div>
                    <div className="photo photo-3"></div>
                    <a className='botaoSaibaMais' href="#">Saiba Mais</a>
                </div>

                <div className="card">
                    <div className="info">
                        <div className="name">Creme de Leite</div>
                        <div className="infoName">Milk Menk</div>
                        <div className="divider"></div>
                        <div className="bio">Lorem ipsum dolor site amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labora et dolore magna aliqua.</div>
                        <button className='botaoContato'>Contate-nos</button>
                    </div>
                    <div className="photo photo-4"></div>
                    <a className='botaoSaibaMais' href="#">Saiba Mais</a>
                </div>

                <div className="card">
                    <div className="info">
                        <div className="name">Creme de Leite</div>
                        <div className="infoName">Milk Menk</div>
                        <div className="divider"></div>
                        <div className="bio">Lorem ipsum dolor site amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labora et dolore magna aliqua.</div>
                        <button className='botaoContato'>Contate-nos</button>
                    </div>
                    <div className="photo photo-5"></div>
                    <a className='botaoSaibaMais' href="#">Saiba Mais</a>
                </div>

                <div className="card">
                    <div className="info">
                        <div className="name">Creme de Leite</div>
                        <div className="infoName">Milk Menk</div>
                        <div className="divider"></div>
                        <div className="bio">Lorem ipsum dolor site amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labora et dolore magna aliqua.</div>
                        <button className='botaoContato'>Contate-nos</button>
                    </div>
                    <div className="photo photo-6"></div>
                    <a className='botaoSaibaMais' href="#">Saiba Mais</a>
                </div>

                <div className="card">
                    <div className="info">
                        <div className="name">Creme de Leite</div>
                        <div className="infoName">Milk Menk</div>
                        <div className="divider"></div>
                        <div className="bio">Lorem ipsum dolor site amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labora et dolore magna aliqua.</div>
                        <button className='botaoContato'>Contate-nos</button>
                    </div>
                    <div className="photo photo-7"></div>
                    <a className='botaoSaibaMais' href="#">Saiba Mais</a>
                </div>

                <div className="card">
                    <div className="info">
                        <div className="name">Creme de Leite</div>
                        <div className="infoName">Milk Menk</div>
                        <div className="divider"></div>
                        <div className="bio">Lorem ipsum dolor site amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labora et dolore magna aliqua.</div>
                        <button className='botaoContato'>Contate-nos</button>
                    </div>
                    <div className="photo photo-8"></div>
                    <a className='botaoSaibaMais' href="#">Saiba Mais</a>
                </div>

                <div className="card">
                    <div className="info">
                        <div className="name">Creme de Leite</div>
                        <div className="infoName">Milk Menk</div>
                        <div className="divider"></div>
                        <div className="bio">Lorem ipsum dolor site amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labora et dolore magna aliqua.</div>
                        <button className='botaoContato'>Contate-nos</button>
                    </div>
                    <div className="photo photo-9"></div>
                    <a className='botaoSaibaMais' href="#">Saiba Mais</a>
                </div>
            </div>
    </div>*/}
        </>
    )
}

export default CardProdutos