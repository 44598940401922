import './ParceirosCidades.css'
import Delicatta from './Images/Delicatta.png'
import EmporioDoPaes from './Images/Emporio-dos-paes.png'
import promel from './Images/Promel.png'
import ArmazemDunia from './Images/ArmazemDunia.png'
import CafeteriaMoretti from './Images/CafeteriaMoretti.png'

const Boituva = () => {
    return (
        <section class="containerMM">
            <div class="nossasLojasMM">

                
            <div class="conteudoMM">
                    <div class="mapaMM">
                        {/* <img className='localImg' src='https://prod-ripcut-delivery.disney-plus.net/v1/variant/disney/46C686E3B18E2FB101C906C29D45E4832C30868A71E5EE4AAF0C9E0835229C05/scale?width=1200&aspectRatio=1.78&format=webp' /> */}
                        <iframe
                            title='ARMAZÉM FLORA'
                            height="300"
                            width="100%"
                            style={{ border: 0, borderRadius: '25px 25px 0px 0px' }}
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3664.901387837648!2d-47.6705129!3d-23.283032600000002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94c5e23379400001%3A0x4b520a33b90b9326!2sR.%20Cel.%20Eug%C3%AAnio%20Motta%2C%20521%20-%20loja%2043%20-%20centro%2C%20Boituva%20-%20SP%2C%2018550-103!5e0!3m2!1spt-BR!2sbr!4v1705677445217!5m2!1spt-BR!2sbr"
                            allowfullscreen
                        ></iframe>
                    </div>

                    <div class="descricaoMM">
                        <h3 class="h3MM">ARMAZÉM FLORA</h3>

                        <div>
                            <p class="pMM">R. Cel Eugênio Motta, 521 - Loja 43<br />
                                Centro - BOITUVA/SP - 18550-103</p>

                            <p class="pMM">TEL: (15) 99631-0087</p>
                        </div>
                    </div>
                </div>
                
                <div class="conteudoMM">
                    <div class="mapaMM">
                        <img className='localImg' src={promel} alt='PROMEL EMPÓRIO'/>
                        <iframe
                            title='PROMEL EMPÓRIO'
                            height="300"
                            width="100%"
                            style={{ border: 0, borderRadius: '25px 25px 0px 0px' }}
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3664.8600907420678!2d-47.67203229999999!3d-23.2845329!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94c5e233186b3045%3A0x30e6e10682358a93!2sRua%20Coronel%20Arruda%20Botelho%2C%20291%20-%20Vila%20Ferriello%2C%20Boituva%20-%20SP%2C%2018550-000!5e0!3m2!1spt-BR!2sbr!4v1705677611981!5m2!1spt-BR!2sbr"
                            allowfullscreen
                        ></iframe>
                    </div>

                    <div class="descricaoMM">
                        <h3 class="h3MM">PROMEL EMPÓRIO</h3>

                        <div>
                            <p class="pMM">R. Coronel Arruda Botelho, 291<br />
                                Vila Ferriello - BOITUVA/SP - 18550-000</p>

                            <p class="pMM">TEL: (15) 3316-5606</p>
                        </div>
                    </div>
                </div>

                <div class="conteudoMM">
                    <div class="mapaMM">
                        <img className='localImg' src={Delicatta} alt='DELICATTA'/>
                        <iframe
                            title='DELICATTA'
                            height="300"
                            width="100%"
                            style={{ border: 0, borderRadius: '25px 25px 0px 0px' }}
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3665.154826978885!2d-47.673713!3d-23.2738233!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94c5e23ae79164db%3A0xf3730960b4e8fa9!2sAv.%20Brasil%2C%20535%20-%20Parque%20Res%20Esplanada%2C%20Boituva%20-%20SP%2C%2018550-000!5e0!3m2!1spt-BR!2sbr!4v1709670813096!5m2!1spt-BR!2sbr"
                            allowfullscreen
                        ></iframe>
                    </div>

                    <div class="descricaoMM">
                        <h3 class="h3MM">DELICATTA</h3>

                        <div>
                            <p class="pMM">Av. Brasil, 535<br />
                                Parque Res Esplanada - BOITUVA/SP - 18550-000</p>

                            {/* <p class="pMM">TEL: (15) 3316-5606</p> */}
                        </div>
                    </div>
                </div>

                <div class="conteudoMM">
                    <div class="mapaMM">
                        <img className='localImg' src={EmporioDoPaes} alt='EMPÓRIO DOS PÃES'/>
                        <iframe
                            title='EMPÓRIO DOS PÃES'
                            height="300"
                            width="100%"
                            style={{ border: 0, borderRadius: '25px 25px 0px 0px' }}
                            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14658.52170670612!2d-47.6503876!3d-23.2928748!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94c5e3b03475acc7%3A0x4063f21d06bd030!2sPadaria%20Emp%C3%B3rio%20dos%20P%C3%A3es!5e0!3m2!1spt-BR!2sbr!4v1709672214575!5m2!1spt-BR!2sbr"
                            allowfullscreen
                        ></iframe>
                    </div>

                    <div class="descricaoMM">
                        <h3 class="h3MM">EMPÓRIO DOS PÃES</h3>

                        <div>
                            <p class="pMM">R. Zélia de Lima Rosa, 489<br />
                                Recanto das Primaveras - BOITUVA/SP - 18550-000</p>

                            {/* <p class="pMM">TEL: (15) 3316-5606</p> */}
                        </div>
                    </div>
                </div>

                <div class="conteudoMM">
                    <div class="mapaMM">
                        <img className='localImg' src={ArmazemDunia} alt='ARMAZéM DUNIA'/>
                        <iframe
                            title='ARMAZéM DUNIA'
                            height="300"
                            width="100%"
                            style={{ border: 0, borderRadius: '25px 25px 0px 0px' }}
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3665.005776834123!2d-47.675626799999996!3d-23.2792398!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94c5e22fd1e87509%3A0x39cc8fd3aba3303b!2sR.%20Ipero%2C%2027%20-%20Jd%20Bela%20Vista%2C%20Boituva%20-%20SP%2C%2018550-000!5e0!3m2!1spt-BR!2sbr!4v1709819955136!5m2!1spt-BR!2sbr"
                            allowfullscreen
                        ></iframe>
                    </div>

                    <div class="descricaoMM">
                        <h3 class="h3MM">ARMAZÉM DUNIA</h3>

                        <div>
                            <p class="pMM">Rua Ipero, 27<br />
                                Jd Bela Vista - BOITUVA/SP - 18550-000</p>

                            {/* <p class="pMM">TEL: (15) 3316-5606</p> */}
                        </div>
                    </div>
                </div>

                <div class="conteudoMM">
                    <div class="mapaMM">
                        {/* <img className='localImg' src={} /> */}
                        <iframe
                            title='PADARIA ANDREAS'
                            height="300"
                            width="100%"
                            style={{ border: 0, borderRadius: '25px 25px 0px 0px' }}
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3664.3499968350006!2d-47.6494608!3d-23.3030568!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94c5e39c173b4263%3A0x339a79e9a1815daa!2sR.%20Z%C3%A9lia%20de%20Lima%20Rosa%2C%201244%20-%20PORTAL%20VILLE%20AZALEIA%2C%20Boituva%20-%20SP%2C%2018552-320!5e0!3m2!1spt-BR!2sbr!4v1709820087890!5m2!1spt-BR!2sbr"
                            allowfullscreen
                        ></iframe>
                    </div>

                    <div class="descricaoMM">
                        <h3 class="h3MM">PADARIA ANDREAS</h3>

                        <div>
                            <p class="pMM">R. Zélia de Lima Rosa, 1244 <br />
                                Portal Ville Azaleia - BOITUVA/SP - 18552-320</p>

                            {/* <p class="pMM">TEL: (15) 3316-5606</p> */}
                        </div>
                    </div>
                </div>

                <div class="conteudoMM">
                    <div class="mapaMM">
                        <img className='localImg' src={CafeteriaMoretti} alt='CAFETERIA MORETTI'/>
                        <iframe
                            title='CAFETERIA MORETTI'
                            height="300"
                            width="100%"
                            style={{ border: 0, borderRadius: '25px 25px 0px 0px' }}
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3665.0354656451937!2d-47.6713747!3d-23.278160999999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94c5e2307e3d4d0d%3A0x96419acf1d005933!2sAv.%20Ver.%20Jos%C3%A9%20%C3%82ngelo%20Biagione%2C%20660%20-%20AC%2C%20Boituva%20-%20SP%2C%2018550-000!5e0!3m2!1spt-BR!2sbr!4v1709820198719!5m2!1spt-BR!2sbr"
                            allowfullscreen
                        ></iframe>
                    </div>

                    <div class="descricaoMM">
                        <h3 class="h3MM">CAFETERIA MORETTI</h3>

                        <div>
                            <p class="pMM">Av. Ver. José Ângelo Biagione, 660<br />
                                AC - BOITUVA/SP - 18550-000</p>

                            {/* <p class="pMM">TEL: (15) 3316-5606</p> */}
                        </div>
                    </div>
                </div>


            </div>
        </section>

    )
}

export default Boituva