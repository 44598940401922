
const Queijos = () => {
    return(
        <div className='cardContainer'>
            <div className='box'>

                <div className="card">
                    <div className="info">
                        <div className="name">Santo Antonio</div>
                        <div className="infoName">Queijo</div>
                        <div className="divider"></div>
                        <div className="bio">Receita desenvolvida na nossa Fazenda Santo Antonio, inspirado no queijo Portugues da Serra da Estrela. Elaborado com 2 tipos de fermentos especiais, que após 30 dias de maturação  garantem ao queijo um sabor aromático, consistência amanteigada e macia. Levemente salgado.</div>
                        {/* <button className='botaoContato'>Contate-nos</button> */}
                    </div>
                    <div className="photo QueijosSantoAntonio"></div>
                    <a className='botaoSaibaMais' href="https://api.whatsapp.com/send/?phone=5515998194162" target="_blank">Saiba Mais</a>
                </div>

                <div className="card">
                    <div className="info">
                        <div className="name">Bolinha</div>
                        <div className="infoName">Queijo Mussarela</div>
                        <div className="divider"></div>
                        <div className="bio">O diferencial da nossa mussarela é a qualidade da materia prima  utilizada,  pois é elaborada a partir da massa de queijo minas  filado,  que  traz ao  produto  um sabor amanteigado e suave.</div>
                        {/* <button className='botaoContato'>Contate-nos</button> */}
                    </div>
                    <div className="photo QueijosMussarelaBolinha"></div>
                    <a className='botaoSaibaMais' href="https://api.whatsapp.com/send/?phone=5515998194162" target="_blank">Saiba Mais</a>
                </div>

                <div className="card">
                    <div className="info">
                        <div className="name">Frescal</div>
                        <div className="infoName">Queijo</div>
                        <div className="divider"></div>
                        <div className="bio">Feito por um processo artesanal, sob rígido controle de qualidade e higiene, com quantidade reduzida de sal (1%) e gordura, consistência  macia e leve. </div>
                        {/* <button className='botaoContato'>Contate-nos</button> */}
                    </div>
                    <div className="photo QueijosFrescal"></div>
                    <a className='botaoSaibaMais' href="https://api.whatsapp.com/send/?phone=5515998194162" target="_blank">Saiba Mais</a>
                </div>

                <div className="card">
                    <div className="info">
                        <div className="name">Ricota</div>
                        <div className="infoName">Queijo</div>
                        <div className="divider"></div>
                        <div className="bio">Diferencia pela consistência macia, sabor suave e muito saborosa. Sem adição de conservantes.</div>
                        {/* <button className='botaoContato'>Contate-nos</button> */}
                    </div>
                    <div className="photo QueijosRicota"></div>
                    <a className='botaoSaibaMais' href="https://api.whatsapp.com/send/?phone=5515998194162" target="_blank">Saiba Mais</a>
                </div>

                <div className="card">
                    <div className="info">
                        <div className="name">Bolinha Temperado</div>
                        <div className="infoName">Queijo Mussarela</div>
                        <div className="divider"></div>
                        <div className="bio">Queijos | Descrição</div>
                        {/* <button className='botaoContato'>Contate-nos</button> */}
                    </div>
                    <div className="photo QueijosMussarelaBolinhaTemperado"></div>
                    <a className='botaoSaibaMais' href="https://api.whatsapp.com/send/?phone=5515998194162" target="_blank">Saiba Mais</a>
                </div>

                <div className="card">
                    <div className="info">
                        <div className="name">Minas Padrão</div>
                        <div className="infoName">Queijo</div>
                        <div className="divider"></div>
                        <div className="bio">Maturado por 15  dias, feito com fermento especial que confere um sabor suave e amanteigado,  além de ter baixa quantidade de sal.</div>
                        {/* <button className='botaoContato'>Contate-nos</button> */}
                    </div>
                    <div className="photo QueijosMinasPadrao"></div>
                    <a className='botaoSaibaMais' href="https://api.whatsapp.com/send/?phone=5515998194162" target="_blank">Saiba Mais</a>
                </div>

                <div className="card">
                    <div className="info">
                        <div className="name">Palito</div>
                        <div className="infoName">Queijo Mussarela</div>
                        <div className="divider"></div>
                        <div className="bio">O diferencial da nossa mussarela é a qualidade da materia prima  utilizada,  pois é elaborada a partir da massa de queijo minas  filado,  que  traz ao  produto  um sabor amanteigado e suave.</div>
                        {/* <button className='botaoContato'>Contate-nos</button> */}
                    </div>
                    <div className="photo QueijosMussarelaPalito"></div>
                    <a className='botaoSaibaMais' href="https://api.whatsapp.com/send/?phone=5515998194162" target="_blank">Saiba Mais</a>
                </div>

                <div className="card">
                    <div className="info">
                        <div className="name">Coalho</div>
                        <div className="infoName">Queijo</div>
                        <div className="divider"></div>
                        <div className="bio">Levemente salgado,  ao ser aquecido na chapa forma  uma crosta crocante por fora e  macio e saboroso por dentro.  Oferecido em barras finas ou barra grandes,   para que o cliente possa corta-lo como preferir.</div>
                        {/* <button className='botaoContato'>Contate-nos</button> */}
                    </div>
                    <div className="photo QueijosCoalho"></div>
                    <a className='botaoSaibaMais' href="https://api.whatsapp.com/send/?phone=5515998194162" target="_blank">Saiba Mais</a>
                </div>

            </div>
        </div>
    )
}

export default Queijos