import './ParceirosCidades.css'
import PadariaSantaTerezinha from './Images/PadariaSantaTerezinha.png'

const Tiete = () => {
    return (
        <section class="containerMM">
            <div class="nossasLojasMM" style={{ gridTemplateColumns: "1fr"}}>

                <div class="conteudoMM">
                    <div class="mapaMM">
                        <img className='localImg' src={PadariaSantaTerezinha} alt='PADARIA SANTA TEREZINHA'/>
                        <iframe
                            title='PADARIA SANTA TEREZINHA'
                            height="300"
                            width="100%"
                            style={{ border: 0, borderRadius: '25px 25px 0px 0px' }}
                            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14678.487616942099!2d-47.710361!3d-23.1109328!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94c60d8ebaa76fa5%3A0x38ac370b49c597d4!2sPadaria%20a%20Confeitaria%20Santa%20Terezinha!5e0!3m2!1spt-BR!2sbr!4v1715866500617!5m2!1spt-BR!2sbr"
                            allowfullscreen
                        ></iframe>
                    </div>

                    <div class="descricaoMM">
                        <h3 class="h3MM">PADARIA SANTA TEREZINHA</h3>

                        <div>
                            <p class="pMM">Rua. Francisco de Toledo, 230<br />
                                Bairro Caixa da Água - TIETÊ/SP - 18530-000</p>

                            <p class="pMM">TEL: (15) 3282-3686</p>
                        </div>
                    </div>
                </div>

            </div>
        </section>

    )
}

export default Tiete