// import ImageSlider from '../components/Galeria/ImageSlider'
// import {SliderData} from '../components/Galeria/SliderData'
//import CardsSocial from '../components/Cards/CardsSocial';
import Slides from  '../components/Slides/Slide'
import Box from '../components/Box/Box'
import Section1 from '../components/Sections/section1/section1'
import Section2 from '../components/Sections/section2/section2'
import Section3 from '../components/Sections/section3/section3'
import Section4 from '../components/Sections/section4/section4'
import Depoimentos from '../components/Depoimentos/Depoimentos'
import BotaoWhatsapp from '../components/BotaoWhatsapp/botaoWhatsapp'

const Home = () => {
    return(
        <>
            
            <Slides/>
            <Box />
            <Section1 />
            <Section2 />
            <Section3 />
            <Depoimentos/>
            <Section4 />

            {/* <ImageSlider slides={SliderData}/> */}

            {/*<CardsSocial />*/}
            <BotaoWhatsapp />
        </>
    )
}

export default Home