import CardProdutos from "../components/Produtos/CardProdutos"
import './Produtos/Produtos.css'
const Produtos = () => {
    return(
        <>
            <CardProdutos />
        </>
    )
}

export default Produtos