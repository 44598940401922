import A2 from './A2.webp'

const Lancamento = () => {
    return(
        <>
            <img style={{width: '85%', margin: '0 auto'}} src={A2} alt="Leite A2"></img>
        </>
    )
}

export default Lancamento