import './ParceirosCidades.css'
import Jequitiba from './Images/Jequitiba.png'
import Remidos from './Images/Remidos.png'
import LojaMilkMenk from './Images/LojaMilkMenk.png'
import SuperBen from './Images/SuperBen.png'
import Elefantao from './Images/Elefantao.png'
import AbiuCerealistaEmpório from './Images/AbiuCerealistaEmpório.png'
// import PadariaRainhaDoPorto from './Images/Pa'

const PortoFeliz = () => {
    return (
        <section class="containerMM">
            <div class="nossasLojasMM">

                <div class="conteudoMM">
                    <div class="mapaMM">
                        <img className='localImg' src={LojaMilkMenk} alt='LOJA MILK MENk' />
                        <iframe
                            title='LOJA MILK MENK'
                            height="300"
                            width="100%"
                            style={{ border: 0, borderRadius: '25px 25px 0px 0px' }}
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3666.7576719229696!2d-47.5244351!3d-23.2155003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94c5fe0b089c0ad9%3A0xa53bff26131adc18!2sAv.%20Jos%C3%A9%20Maurino%2C%20239%2C%20Porto%20Feliz%20-%20SP%2C%2018540-000!5e0!3m2!1spt-BR!2sbr!4v1705677240720!5m2!1spt-BR!2sbr"
                            allowfullscreen
                        ></iframe>
                    </div>

                    <div class="descricaoMM">
                        <h3 class="h3MM">LOJA MILK MENK</h3>

                        <div>
                            <p class="pMM">Av. José Maurino, 239<br />
                                Centro - Porto Feliz/SP - 18540-000</p>

                            <p class="pMM">TEL: (15) 99819-4162</p>
                        </div>
                    </div>
                </div>

                <div class="conteudoMM">
                    <div class="mapaMM">
                        <img className='localImg' src={Jequitiba} alt='MERCEARIA JEQUITIBA' />
                        <iframe
                            title='MERCEARIA JEQUITIBA'
                            height="300"
                            width="100%"
                            style={{ border: 0, borderRadius: '25px 25px 0px 0px' }}
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3667.7182213528963!2d-47.533118400000006!3d-23.1804822!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94c607360dc7eedd%3A0xbd1f9fb07084b30!2sR.%20Sapucaia%2C%20311%2C%20Porto%20Feliz%20-%20SP%2C%2018540-000!5e0!3m2!1spt-BR!2sbr!4v1709669597550!5m2!1spt-BR!2sbr"
                            allowfullscreen
                        ></iframe>
                    </div>

                    <div class="descricaoMM">
                        <h3 class="h3MM">MERCEARIA JEQUITIBA</h3>

                        <div>
                            <p class="pMM">Rua Sapucaia, 311<br />
                                Altos do Jequitibá - Porto Feliz/SP - 18540-000</p>

                            {/* <p class="pMM">TEL: (15) 99819-4162</p> */}
                        </div>
                    </div>
                </div>

                <div class="conteudoMM">
                    <div class="mapaMM">
                        <img className='localImg' src={Remidos} alt='REMIDOS MERCADO E PADARIA' />
                        <iframe
                            title='REMIDOS MERCADO E PADARIA'
                            height="300"
                            width="100%"
                            style={{ border: 0, borderRadius: '25px 25px 0px 0px' }}
                            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3666.964767745587!2d-47.50785542737483!3d-23.20795456893571!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94c5ffde0ec63eff%3A0x1aa54aa677a66394!2sMercado%20Remidos!5e0!3m2!1spt-BR!2sbr!4v1709671526682!5m2!1spt-BR!2sbr"
                            allowfullscreen
                        ></iframe>
                    </div>

                    <div class="descricaoMM">
                        <h3 class="h3MM">REMIDOS MERCADO E PADARIA</h3>

                        <div>
                            <p class="pMM">Av. Governador Mário Covas, 775<br />
                                Belo Alto - Porto Feliz/SP - 18546-228</p>

                            {/* <p class="pMM">TEL: (15) 99819-4162</p> */}
                        </div>
                    </div>
                </div>

                <div class="conteudoMM">
                    <div class="mapaMM">
                        {/* <img className='localImg' src={} /> */}
                        <iframe
                            title='EMPORIO FERRARI'
                            height="300"
                            width="100%"
                            style={{ border: 0, borderRadius: '25px 25px 0px 0px' }}
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3666.795683258091!2d-47.519027113123265!3d-23.21411549486898!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94c5fe133528a027%3A0x933a04c2ff576f61!2sAv.%20Ivete%20Gibim%20Alcala%2C%20290%2C%20Porto%20Feliz%20-%20SP%2C%2018540-000!5e0!3m2!1spt-BR!2sbr!4v1709818771597!5m2!1spt-BR!2sbr"
                            allowfullscreen
                        ></iframe>
                    </div>

                    <div class="descricaoMM">
                        <h3 class="h3MM">EMPÓRIO FERRARI</h3>

                        <div>
                            <p class="pMM">Av. Iveta Gibim Alcalá, 290<br />
                                Vila Alcalá - Porto Feliz/SP - 18546-028</p>

                            {/* <p class="pMM">TEL: (15) 99819-4162</p> */}
                        </div>
                    </div>
                </div>

                <div class="conteudoMM">
                    <div class="mapaMM">
                        <img className='localImg' src={SuperBen} alt='SUPERBEN SUPERMERCADOS' />
                        <iframe
                            title='SUPERBEN SUPERMERCADOS'
                            height="300"
                            width="100%"
                            style={{ border: 0, borderRadius: '25px 25px 0px 0px' }}
                            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14667.863359375075!2d-47.5216597!3d-23.2079155!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94c5fe0f43e3b451%3A0x87ee2ba643ba2e6f!2sSupermercado%20Super%20Bem!5e0!3m2!1spt-BR!2sbr!4v1709818989592!5m2!1spt-BR!2sbr"
                            allowfullscreen
                        ></iframe>
                    </div>

                    <div class="descricaoMM">
                        <h3 class="h3MM">SUPERBEN SUPERMERCADOS</h3>

                        <div>
                            <p class="pMM"> Av. Cap. Joaquim Floriano de Toledo, 625-633<br />
                                Centro - Porto Feliz/SP - 18540-000</p>

                            {/* <p class="pMM">TEL: (15) 99819-4162</p> */}
                        </div>
                    </div>
                </div>

                <div class="conteudoMM">
                    <div class="mapaMM">
                        <img className='localImg' src={Elefantao} alt='ELEFANTÃO HORTIFRUTI' />
                        <iframe
                            title='ELEFANTAO HORTIFRUTI'
                            height="300"
                            width="100%"
                            style={{ border: 0, borderRadius: '25px 25px 0px 0px' }}
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3666.695017949082!2d-47.5254641!3d-23.217782699999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94c5fe0ad37312df%3A0x4d89ca0cf8d14ead!2sRua%20Newton%20Prado%2C%2040%2C%20Porto%20Feliz%20-%20SP%2C%2018540-000!5e0!3m2!1spt-BR!2sbr!4v1709819104100!5m2!1spt-BR!2sbr"
                            allowfullscreen
                        ></iframe>
                    </div>

                    <div class="descricaoMM">
                        <h3 class="h3MM">ELEFANTÃO HORTIFRUTI</h3>

                        <div>
                            <p class="pMM">Rua Newton Prado, 40<br />
                                Centro - Porto Feliz/SP - 18540-000</p>

                            {/* <p class="pMM">TEL: (15) 99819-4162</p> */}
                        </div>
                    </div>
                </div>

                <div class="conteudoMM">
                    <div class="mapaMM">
                        <img className='localImg' src={AbiuCerealistaEmpório} alt='ÁBIU CEREALISTA EMPÓRIO'/>
                        <iframe
                            title='ÁBIU CEREALISTA EMPÓRIO'
                            height="300"
                            width="100%"
                            style={{ border: 0, borderRadius: '25px 25px 0px 0px' }}
                            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14668.561240703904!2d-47.5278556!3d-23.2015567!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94c89bbdacbe4db3%3A0x4dccf741e3f2350c!2sAbiu%20Cerealista%3A%20Produtos%20naturais%20e%20Emp%C3%B3rio!5e0!3m2!1spt-BR!2sbr!4v1709819458346!5m2!1spt-BR!2sbr"
                            allowfullscreen
                        ></iframe>
                    </div>

                    <div class="descricaoMM">
                        <h3 class="h3MM">ÁBIU CEREALISTA EMPÓRIO</h3>

                        <div>
                            <p class="pMM">Av. Armando Sales de Oliveira, 351<br />
                                Centro - Porto Feliz/SP - 18542-074</p>

                            {/* <p class="pMM">TEL: (15) 99819-4162</p> */}
                        </div>
                    </div>
                </div>

                <div class="conteudoMM">
                    <div class="mapaMM">
                        {/* <img className='localImg' src={} /> */}
                        <iframe
                            title='PADARIA RAINHA DO PORTO'
                            height="300"
                            width="100%"
                            style={{ border: 0, borderRadius: '25px 25px 0px 0px' }}
                            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14665.441918045593!2d-47.5245604!3d-23.2299659!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94c5fe7950f068e7%3A0xa3add00b11e8824e!2sPadaria%20e%20Confeitaria%20Rainha%20do%20Porto!5e0!3m2!1spt-BR!2sbr!4v1709819582823!5m2!1spt-BR!2sbr"
                            allowfullscreen
                        ></iframe>
                    </div>

                    <div class="descricaoMM">
                        <h3 class="h3MM">PADARIA RAINHA DO PORTO</h3>

                        <div>
                            <p class="pMM">R. Jéferson Soares de Souza, 14<br />
                                Bambu - Porto Feliz/SP - 18540-542</p>

                            {/* <p class="pMM">TEL: (15) 99819-4162</p> */}
                        </div>
                    </div>
                </div>


            </div>
        </section>

    )
}

export default PortoFeliz