
const Requeijao = () => {
    return(
        <div className='cardContainer'>
            <div className='box'>

                <div className="card">
                    <div className="info">
                        <div className="name">Cremoso</div>
                        <div className="infoName">Requeijão</div>
                        <div className="divider"></div>
                        <div className="bio">Feito  com queijo minas fundido e creme de leite fresco,  oferecendo um produto  de sabor amanteigado e suave. Por não possuir espessantes como goma e amido, não tem estabilidade ao calor, sendo absorvido totalmente quando aplicado por exemplo em bordas de pizza.</div>
                        {/* <button className='botaoContato'>Contate-nos</button> */}
                    </div>
                    <div className="photo Requeijao"></div>
                    <a className='botaoSaibaMais' href="https://api.whatsapp.com/send/?phone=5515998194162" target="_blank">Saiba Mais</a>
                </div>

            </div>
        </div>
    )
}

export default Requeijao