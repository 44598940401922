
const ManteigaGhee = () => {
    return(
        <div className='cardContainer'>
            <div className='box'>

                <div className="card">
                    <div className="info">
                        <div className="name">Ghee</div>
                        <div className="infoName">Manteiga</div>
                        <div className="divider"></div>
                        <div className="bio">É a manteiga totalmente purificada (clarificada), cor amarelo-ambar de sabor levemente caramelizada e consistência macia. Tem a remoção cuidadosa de toda a água e os elementos sólidos (como a caseína, e a lactose) após a fervura por 6 horas e a filtragem em tecido fino para obter-se um produto de qualidade. </div>
                        {/* <button className='botaoContato'>Contate-nos</button> */}
                    </div>
                    <div className="photo ManteigaGhee"></div>
                    <a className='botaoSaibaMais' href="https://api.whatsapp.com/send/?phone=5515998194162" target="_blank">Saiba Mais</a>
                </div>

            </div>
        </div>
    )
}

export default ManteigaGhee