import "./valores.css"
import img from "./valores.jpg"

const Valores = () => {
    return(
        <>
        <section>
            <div className="titulo-banner">
                <div className="bg">
                    <h1>Ideologia de vida</h1>
                </div>
            </div>
        </section>
        <section className="valores-container">
            <h1 className="titulo-valores">Valores</h1>
            <p>Todas as medidas tomadas dentro da Milk Menk são adotadas respeitando 3 princípios básicos que temos como ideologia de vida. Esses princípios, ou valores, compõem nossa verdadeira essência e nos tornam praticamente únicos no mercado simplesmente por não pensar apenas em nosso bem estar, mas pensar também na reflexão dos nossos atos sobre o todo.</p>
            <div className="valores-conteudo">
                <div className="valores-group">
                    <div className="valores-item-h">
                        <img src={img}></img>
                        <div className="valores-descricao">
                            <h1>Produtos</h1>
                            <p>Trabalhando sempre com muita transparência, nós temos total respeito ao nosso consumidor e isso resulta em um maior cuidado com a qualidade final dos produtos, garantindo que sejam além de saborosos, muito saudáveis, ideal para ser consumido por toda família.</p>
                        </div>
                    </div>

                    <div className="valores-item-h">
                        <div className="valores-descricao">
                            <h1>Consciência Ambiental e Social</h1>
                            <p>Para toda ação existe uma reação. E pensando no bem estar das nossas futuras gerações a Milk Menk se predispôs a ser uma empresa sustentável, transformando os dejetos em fonte de energia renovável não agredindo assim o nosso meio ambiente.</p>
                        </div>
                        <img src={img}></img>
                    </div>
                </div>

                <div className="valores-item-v">
                    <img src={img}></img>
                    <div className="valores-descricao">
                        <h1>Valores Familiares</h1>
                        <p>Ter valores familiares nada mais é do que ser uma empresa companheira e amigável, além de ter reciprocidade no sentimento de bem querer tanto para nossos consumidores como para funcionários e parceiros. E isso é o que nos move a levar produtos naturais da fazenda para sua família.</p>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}

export default Valores