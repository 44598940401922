import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import "./Contato.css"

const Contato = () => { 
    const [nome, setNome] = useState('');
    const [telefone, setTelefone] = useState('');
    const [email, setEmail] = useState('');
    const [mensagem, setMensagem] = useState('');
    const [enviadoComSucesso, setEnviadoComSucesso] = useState(false);
  
    const handleSubmit = async (e) => {
      e.preventDefault();
  
      // Aqui você pode adicionar lógica para validar os campos do formulário
  
      // Enviar os dados para o servidor
      const response = await fetch('/enviar-email.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          nome,
          telefone,
          email,
          mensagem,
        }),
      });
  
      // Manipular a resposta do servidor conforme necessário
      const result = await response.text();
      console.log(result);

      if (result.includes('E-Mail enviado com sucesso')) {
        // Se o e-mail foi enviado com sucesso, definir o estado para mostrar a página de sucesso
        setEnviadoComSucesso(true);
      } else {
        // Se houver um erro, você pode exibir uma mensagem de erro ou tomar outras ações necessárias
        console.error('Erro ao enviar e-mail:', result);
      }
    };

    // Se o e-mail foi enviado com sucesso, redirecionar para a página de sucesso
    if (enviadoComSucesso) {
        return <Navigate to="/EnviadoComSucesso" />;
        // onEnvioSucesso();
    }

    return(
        <section className='section-form'>
            <div className='banner-form'>
                <div className='titulo-faixa'>
                    Contato
                </div>    
            </div>
            <div className='div-form'>
                <form onSubmit={handleSubmit}>
                    <label htmlFor="nome">Nome:</label>
                    <input
                    type="text"
                    id="nome"
                    name="nome"
                    value={nome}
                    onChange={(e) => setNome(e.target.value)}
                    placeholder='Digite seu nome'
                    required
                    />

                    <label htmlFor="email">Email:</label>
                    <input
                    type="email"
                    id="email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder='Digite seu e-mail'
                    required
                    />

                    <label htmlFor="telefone">Telefone:</label>
                    <input
                    type="tel"
                    id="telefone"
                    name="telefone"
                    value={telefone}
                    onChange={(e) => setTelefone(e.target.value)}        
                    placeholder='Digite seu nº de celular'
                    required
                    />

                    <label htmlFor="mensagem">Mensagem:</label>
                    <textarea
                    id="mensagem"
                    name="mensagem"
                    value={mensagem}
                    onChange={(e) => setMensagem(e.target.value)}
                    rows="4"
                    placeholder='Digite sua mensagem...'
                    required
                    ></textarea>

                    <input type='hidden' name='_gotcha' />
                    
                    <div className='div-button'>
                        <button type="submit">Enviar</button>
                    </div>
                </form>
            </div>
        </section>
    )
}

export default Contato