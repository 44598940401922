
const CremeDeLeite = () => {
    return(
        <div className='cardContainer'>
            <div className='box'>

                <div className="card">
                    <div className="info">
                        <div className="name">Creme de Leite</div>
                        <div className="infoName">Natural</div>
                        <div className="divider"></div>
                        <div className="bio">É o tradicional creme de leite fresco, feito sem adição de conservantes, espessantes ou emulsificantes. Possui  teor de gordura de 35% e diluição em leite, não em água como a maioria encontrada no mercado, isto confere ao creme um sabor amanteigado delicioso.</div>
                        {/* <button className='botaoContato'>Contate-nos</button> */}
                    </div>
                    <div className="photo CremeDeLeite"></div>
                    <a className='botaoSaibaMais' href="https://api.whatsapp.com/send/?phone=5515998194162" target="_blank">Saiba Mais</a>
                </div>

            </div>
        </div>
    )
}

export default CremeDeLeite