import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import './App.css';
import './fonts/fontes.css';
import ScrollToTop from './components/ScrollToTop';

//Componentes
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';

//Páginas Route
import Home from './pages/Home'
import Lancamento from './pages/Lancamento'
import Produtos from './pages/Produtos'
import Parceiros from './pages/Parceiros'
import Sobre from './pages/Sobre'
import Receitas from './pages/Receitas'
import Contato from './pages/Contato/Contato'
import FAQ from './pages/FAQ'

//Sub Páginas
import Historia from './pages/Historia'
import Valores from './pages/Valores'
import ConcienciaSustentavel from './pages/ConcienciaSustentavel'
import Gado from './pages/Gado'

//Sub Páginas Produtos
import Coalhada from './pages/Produtos/Coalhada';
import CremeDeLeite from './pages/Produtos/CremeDeLeite';
import DoceDeLeite from './pages/Produtos/DoceDeLeite';
import Iogurte from './pages/Produtos/Iogurte';
import Leite from './pages/Produtos/Leite';
import Manteiga from './pages/Produtos/Manteiga';
import ManteigaGhee from './pages/Produtos/ManteigaGhee';
import Queijos from './pages/Produtos/Queijos';
import Requeijao from './pages/Produtos/Requeijao';

//Parceiros
import Boituva from './pages/Parceiros/Boituva';
// import Campinas from './pages/Parceiros/Campinas';
import Capivari from './pages/Parceiros/Capivari';
import CesarioLange from './pages/Parceiros/CesarioLange';
import Indaiatuba from './pages/Parceiros/Indaiatuba';
import Itu from './pages/Parceiros/Itu';
import PortoFeliz from './pages/Parceiros/PortoFeliz';
import Sorocaba from './pages/Parceiros/Sorocaba';
import Tiete from './pages/Parceiros/Tiete';

//404
import Erro404 from './404';

//Email Enviado Com Sucesso
import EnviadoComSucesso from './pages/Contato/EnviadoComSucesso'

function App() {
  // const [emailEnviadoComSucesso, setEmailEnviadoComSucesso] = useState(false); 

  return (
    <Router>
      <ScrollToTop />
      <Navbar />
      <Routes>
        <Route path='/' exact element={<Home/>} />
        <Route path='/Lancamento' exact element={<Lancamento/>} />
        <Route path='/Produtos' element={<Produtos/>} />
        <Route path='/Parceiros' element={<Parceiros/>} />
        <Route path='/Sobre' element={<Sobre/>} />
        <Route path='/Receitas' element={<Receitas/>} />
        <Route path='/Contato' element={<Contato />} />
        <Route path='/FAQ' element={<FAQ/>} />

        <Route path='/Historia' element={<Historia/>} />
        <Route path='/Valores' element={<Valores/>} />
        <Route path='/ConcienciaSustentavel' element={<ConcienciaSustentavel/>} />
        <Route path='/Gado' element={<Gado/>} />

        <Route path='/Coalhada' element={<Coalhada/>} />
        <Route path='/CremeDeLeite' element={<CremeDeLeite/>} />
        <Route path='/DoceDeLeite' element={<DoceDeLeite/>} />
        <Route path='/Iogurte' element={<Iogurte/>} />
        <Route path='/Leite' element={<Leite/>} />
        <Route path='/Manteiga' element={<Manteiga/>} />
        <Route path='/ManteigaGhee' element={<ManteigaGhee/>} />
        <Route path='/Requeijao' element={<Requeijao/>} />
        <Route path='/Queijos' element={<Queijos/>} />
        
        <Route path='/Boituva' element={<Boituva/>} />
        {/* <Route path='/Campinas' element={<Campinas/>} /> */}
        <Route path='/Capivari' element={<Capivari/>} />
        <Route path='/CesarioLange' element={<CesarioLange/>} />
        <Route path='/Indaiatuba' element={<Indaiatuba/>} />
        <Route path='/Itu' element={<Itu/>} />
        <Route path='/PortoFeliz' element={<PortoFeliz/>} />
        <Route path='/Sorocaba' element={<Sorocaba/>} />
        <Route path='/Tiete' element={<Tiete/>} />

        
        <Route path='/404' element={<Erro404/>} />

        <Route path="/EnviadoComSucesso" exact element={ < EnviadoComSucesso /> } />

        {/* {emailEnviadoComSucesso ? (
          <Route path="/EnviadoComSucesso" exact element={<EnviadoComSucesso />} />
        ) : (
          <Route path="/EnviadoComSucesso" element={<Navigate to="/Contato" />} />
        )} */}

        <Route path='*' element={<Navigate to="/404" />} /> {/* Se não encontrar a rota, irá cai no /404*/}
        
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;