// ERRO 404
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import './404.css'

const Erro404 = () => {

    // Função para redirecionar a Home com o botão
    const navigate = useNavigate();
    const goHome = useCallback(() => {
        navigate('/');
    }, []); // onClick={goHome} ---> colocar em um botão para retornar a página Home

    const goContato = useCallback(() => {
        navigate('/Contato');
    }, []);
    return (
        <>
            <section className='error-404'>
                <div className='error-page'>
                    <div className='error-container'>
                        <h1 data-text="404">404</h1>
                        <h4 data-text="Ops! Página não encontrada">Ops! Página não encontrada</h4>
                        <p>Desculpe, a página que você procura não existe. Se você acha que algo está errado, reporte o problema.</p>
                        <div className='error-btns'>
                            <a onClick={goHome} >Voltar para o Início</a>
                            <a onClick={goContato} >Reportar Problema</a>
                        </div>
                    </div>

                    {/* <button onClick={goHome}> Erro 404, click aqui para voltar a tela inicial</button> */}
                </div>
            </section>
        </>
    )
}

export default Erro404